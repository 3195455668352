import React from 'react';
import '../stylesheets/PrivacyPolicy.css';

const TermsAndConditionsEN = () => {
  return (
    <div className="privacy-policy">
      <h1>Terms and Conditions for Clean Africa</h1>
      <p><strong>Effective Date:</strong> July 04th, 2024</p>
      
      <p>Welcome to Clean Africa! These Terms and Conditions ("Terms") govern your use of the Clean Africa application ("App"), which empowers users to report waste scenes and garbage in their community for better waste management and a more sustainable community living. By using the App, you agree to comply with and be bound by these Terms.</p>
      
      <h2>1. Acceptance of Terms</h2>
      <p>By downloading, installing, or using the App, you agree to these Terms. If you do not agree with these Terms, please do not use the App:</p>
      
      <h2>2. Use of the App</h2>
      <p>Clean Africa is a free-to-use application that allows users to report waste scenes and garbage in their community. By using the App, you acknowledge that:</p>
      <ul>
        <li>You are responsible for any content you submit, including images and descriptions of waste scenes.</li>
        <li>You will not use the App for any unlawful or prohibited activities.</li>
        <li>You will provide accurate and truthful information when reporting waste scenes.</li>
      </ul>
      
      <h2>3. Image Submission and Usage Rights</h2>
      <p>When you submit images through the App, you grant Clean Africa a non-exclusive, royalty-free, worldwide, perpetual license to use, reproduce, distribute, and display the images for purposes related to the App’s functionality and mission. This includes using the images for:</p>
      <ul>
        <li>Waste management and cleanup efforts.</li>
        <li>Educational and promotional purposes to raise awareness about waste issues.</li>
        <li>You acknowledge that you will not receive any compensation for submitting images or labeling images in the App.</li>
      </ul>

      <h2>4. User Conduct</h2>
      <p>You agree not to use the App to:</p>
      <ul>
        <li>Submit any false or misleading information.</li>
        <li>Upload or share any content that is offensive, harmful, or violates any laws or third-party rights.</li>
        <li>Engage in any activities that could harm the App or its users.</li>
      </ul>
      
      <h2>5. Intellectual Property</h2>
      <p>All content, features, and functionality of the App, including text, graphics, logos, and software, are the exclusive property of Clean Africa or its licensors and are protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any content of the App without prior written permission from Clean Africa.</p>
      
      <h2>6. Disclaimer of Warranties</h2>
      <p>The App is provided "as is" and "as available," without any warranties of any kind, either express or implied. Clean Africa does not warrant that the App will be uninterrupted, error-free, or free of viruses or other harmful components</p>

      <h2>7. Limitation of Liability</h2>
      <p> In no event shall Clean Africa be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of data, profits, or goodwill, arising out of or in connection with your use of the App.</p>
      
      <h2>8. Modifications to Terms</h2>
      <p>Clean Africa reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms in the App. Your continued use of the App following the posting of changes constitutes your acceptance of those changes</p>

      <h2>9. Termination</h2>
      <p> Clean Africa may terminate or suspend your access to the App at any time, without prior notice or liability, for any reason, including if you breach these Terms.</p>

      <h2>10. Governing Law</h2>
      <p> These Terms shall be governed by and construed in accordance with the laws of Togo, without regard to its conflict of law provisions.</p>

      <h2>11. Contact Us</h2>
      <p>If you have any questions or concerns about this privacy policy, please contact us at: <a href="mailto:info@clean.africa">info@clean.africa</a></p>
      
      <p>Thank you for using Clean Africa.</p>
    </div>
  );
};

export default TermsAndConditionsEN;
