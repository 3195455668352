import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TermsAndConditionsFR from './components/TermsAndConditionsFR';
import TermsAndConditionsEN from './components/TermsAndConditionsEN';
import PrivacyPolicyEN from './components/PrivacyPolicyEN';
import PrivacyPolicyFR from './components/PrivacyPolicyFR';
import DeletionSteps from './components/DeletionSteps'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/fr_terms-and-conditions" element={<TermsAndConditionsFR />} />
        <Route path="/en_terms-and-conditions" element={<TermsAndConditionsEN />} />
        <Route path="/fr_privacy-policy" element={<PrivacyPolicyFR />} />
        <Route path="/en_privacy-policy" element={<PrivacyPolicyEN />} />
        <Route path="/deletion-steps" element={<DeletionSteps />} />
      </Routes>
    </Router>
  );
};

export default App;