import React from 'react';
import '../stylesheets/PrivacyPolicy.css';

const DeletionSteps = () => {
  return (
    <div className="privacy-policy" style={{ width: '500px' }}>      
      <h1>Steps to delete your account</h1>
      <ol>
        <li>Login to your account on Clean Africa App</li>
        <li>Go to profile page</li>
        <li>Tap on Delete Account button</li>
        <li>After your confirmation your account will be deleted permanently</li>
      </ol>
    </div>
  );
};

export default DeletionSteps;
