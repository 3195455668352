import React from 'react';
import '../stylesheets/PrivacyPolicy.css';

const TermsAndConditionsFR = () => {
  return (
    <div className="privacy-policy">
      <h1>Conditions Générales d'Utilisation de Clean Africa</h1>
      <p><strong>Date d'Entrée en vigueur:</strong> 04 juillet 2024</p>
      
      <p>Bienvenue sur Clean Africa ! Les présentes Conditions Générales d'Utilisation ("Conditions") régissent votre utilisation de l'application Clean Africa ("Application"), qui permet aux utilisateurs de signaler les dépôts sauvages et les ordures dans leur communauté pour une meilleure gestion des déchets et un mode de vie communautaire plus durable. En utilisant l'application, vous acceptez de vous conformer à ces Conditions et d'être lié par celles-ci.</p>
      
      <h2>1. Acceptation des Conditions</h2>
      <p>En téléchargeant, installant ou utilisant l'application, vous acceptez les présentes Conditions. Si vous n'êtes pas d'accord avec ces Conditions, veuillez ne pas utiliser l'application.</p>
      
      <h2>2. Utilisation de l'Application</h2>
      <p>Clean Africa est une application gratuite qui permet aux utilisateurs de signaler les dépôts sauvages et les ordures dans leur communauté. En utilisant l'application, vous reconnaissez que:</p>
      <ul>
        <li>Vous êtes responsable de tout contenu que vous soumettez, y compris les images et les descriptions des dépôts sauvages.</li>
        <li>Vous n'utiliserez pas l'application pour des activités illégales ou interdites.</li>
        <li>Vous fournirez des informations exactes et véridiques lors du signalement des dépôts sauvages.</li>
      </ul>
      
      <h2>3. Soumission d'Images et Droits d'Utilisation</h2>
      <p>Lorsque vous soumettez des images via l'application, vous accordez à Clean Africa une licence non exclusive, libre de redevance, mondiale et perpétuelle pour utiliser, reproduire, distribuer et afficher les images à des fins liées à la fonctionnalité et à la mission de l'application. Cela comprend l'utilisation des images pour:</p>
      <ul>
        <li>La gestion et le nettoyage des déchets.</li>
        <li>Des fins éducatives et promotionnelles pour sensibiliser aux problèmes de déchets.</li>
        <li>Vous reconnaissez que vous ne recevrez aucune compensation pour la soumission d'images ou l'étiquetage d'images dans l'application.</li>
      </ul>

      <h2>4. Conduite de l'Utilisateur</h2>
      <p>Vous acceptez de ne pas utiliser l'application pour:</p>
      <ul>
        <li>Soumettre des informations fausses ou trompeuses.</li>
        <li>Télécharger ou partager tout contenu offensant, nuisible ou violant les lois ou les droits de tiers.</li>
        <li>Participer à toute activité susceptible de nuire à l'application ou à ses utilisateurs.</li>
      </ul>
      
      <h2>5. ropriété Intellectuelle</h2>
      <p>Tout le contenu, les fonctionnalités et les fonctions de l'application, y compris les textes, les graphiques, les logos et les logiciels, sont la propriété exclusive de Clean Africa ou de ses concédants de licence et sont protégés par les lois sur la propriété intellectuelle. Vous ne pouvez pas reproduire, distribuer ou créer des œuvres dérivées de tout contenu de l'application sans l'autorisation écrite préalable de Clean Africa.</p>
      
      <h2>6. Exclusion de Garanties</h2>
      <p>L'application est fournie "telle quelle" et "selon sa disponibilité", sans aucune garantie d'aucune sorte, expresse ou implicite. Clean Africa ne garantit pas que l'application sera ininterrompue, sans erreur ou exempte de virus ou d'autres éléments nuisibles.</p>

      <h2>7. Limitation de Responsabilité</h2>
      <p> En aucun cas, Clean Africa ne pourra être tenu responsable de dommages indirects, accessoires, spéciaux, consécutifs ou punitifs, y compris, mais sans s'y limiter, la perte de données, de profits ou de clientèle, résultant de ou liés à votre utilisation de l'application.</p>
      
      <h2>8. Modification des Conditions</h2>
      <p>Clean Africa se réserve le droit de modifier les présentes Conditions à tout moment. Toute modification entrera en vigueur dès la publication des Conditions mises à jour dans l'application. Votre utilisation continue de l'application après la publication des modifications constitue votre acceptation de ces modifications.</p>

      <h2>9. Résiliation</h2>
      <p> Clean Africa peut résilier ou suspendre votre accès à l'application à tout moment, sans préavis ni responsabilité, pour quelque raison que ce soit, y compris en cas de violation des présentes Conditions.</p>

      <h2>10. Loi Applicable</h2>
      <p> Les présentes Conditions seront régies et interprétées conformément au droit du Togo, sans tenir compte de ses dispositions relatives aux conflits de lois.</p>

      <h2>11. Contactez-Nous</h2>
      <p>Si vous avez des questions ou des préoccupations concernant les présentes Conditions, veuillez nous contacter à: <a href="mailto:info@clean.africa">info@clean.africa</a></p>
      
      <p>Merci d'utiliser Clean Africa.</p>
    </div>
  );
};

export default TermsAndConditionsFR;
