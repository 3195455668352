import React from 'react';
import '../stylesheets/PrivacyPolicy.css';

const PrivacyPolicyFR = () => {
  return (
    <div className="privacy-policy">
      <h1>Politique de Confidentialité de Clean Africa</h1>
      <p><strong>Date d'entrée en vigueur:</strong> 04 Juillet 2024</p>
      
      <p>Chez Clean Africa, nous prenons la confidentialité de nos utilisateurs très au sérieux. Cette politique de confidentialité décrit les types de données que nous collectons, comment nous les utilisons et les mesures que nous prenons pour protéger vos informations personnelles</p>
      
      <h2>1. Collecte et Utilisation des Informations</h2>
      <p>Lorsque vous utilisez notre application, nous pouvons collecter et utiliser les informations suivantes :</p>
      <ul>
        <li><strong>Localisation Approximative ou Précise:</strong> Nous utilisons votre localisation, approximative ou précise, pour déterminer l'emplacement à partir duquel un rapport de déchet est généré par l'utilisateur. Cela nous aide à coordonner efficacement les opérations de nettoyage.</li>
        <li><strong>Permission Biométrique:</strong> Nous utilisons des données biométriques pour la vérification de compte. Cela peut inclure des empreintes digitales, la reconnaissance faciale ou d'autres formes de données biométriques.</li>
        <li><strong>Adresse IP:</strong> Nous utilisons votre adresse IP pour déterminer votre pays et afficher le code de pays pertinent dans les champs de numéro de téléphone. Cela nous permet de personnaliser votre expérience et de simplifier la saisie de vos informations de contact.</li>
        <li><strong>Identifiant de l'Appareil:</strong> Nous utilisons l'identifiant unique de votre appareil pour mettre en place une vérification en deux étapes. Cela ajoute une couche de sécurité supplémentaire à votre compte.</li>
        <li><strong>Appareil Photo:</strong> Nous utilisons la caméra de votre appareil pour prendre des photos des déchets, lesquelles sont ensuite sauvegardées sur notre serveur pour une utilisation ultérieure. Ces images nous aident à documenter et à planifier les interventions de nettoyage.</li>
      </ul>
      
      <h2>2. Partage et Divulgation des Informations</h2>
      <p>Nous ne vendons, louons ou partageons vos informations personnelles avec des tiers sans votre consentement, sauf si cela est nécessaire pour:</p>
      <ul>
        <li>Respecter les lois en vigueur ou répondre à des demandes légales;</li>
        <li>Protéger nos droits ou la sécurité de nos utilisateurs;</li>
        <li>Améliorer et optimiser nos services.</li>
      </ul>
      
      <h2>3. Sécurité des Données</h2>
      <p>Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos informations contre l'accès non autorisé, l'altération, la divulgation ou la destruction. Cela inclut l'utilisation de technologies de cryptage et de protocoles de sécurité stricts.</p>
      
      <h2>4. Vos Droits</h2>
      <p>Vous avez le droit d'accéder, de corriger ou de supprimer vos informations personnelles que nous détenons. Vous pouvez également vous opposer au traitement de vos données personnelles dans certaines circonstances. Pour exercer ces droits, veuillez nous contacter à l'adresse suivante:</p>
      <address>
        Haagbo<br/>
        1, rue du Cimetière Djama<br/>
        BP 76 Atakpamé<br/>
        TOGO<br/>
        Email: <a href="mailto:privacy@clean.africa">privacy@clean.africa</a>
      </address>
      
      <h2>5. Modifications de cette Politique</h2>
      <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page avec une date d'entrée en vigueur mise à jour. Nous vous encourageons à consulter régulièrement cette politique pour rester informé de la manière dont nous protégeons vos informations.</p>
      
      <h2>Contact</h2>
      <p>Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, veuillez nous contacter à: <a href="mailto:privacy@clean.africa">privacy@clean.africa</a></p>
      
      <p>Merci d'utiliser Clean Africa.</p>
    </div>
  );
};

export default PrivacyPolicyFR;
