import React from 'react';
import '../stylesheets/PrivacyPolicy.css';

const PrivacyPolicyEN = () => {
  return (
    <div className="privacy-policy">
      <h1>Clean Africa Privacy Policy</h1>
      <p><strong>Effective Date:</strong> July 04th, 2024</p>
      
      <p>At Clean Africa, we take our users' privacy very seriously. This privacy policy outlines the types of data we collect, how we use it, and the measures we take to protect your personal information.</p>
      
      <h2>1. Information Collection and Use</h2>
      <p>When you use our application, we may collect and use the following information:</p>
      <ul>
        <li><strong>Approximate or Precise Location:</strong> We use your approximate or precise location to determine the location from which a waste report is being generated by the user. This helps us coordinate cleaning operations effectively.</li>
        <li><strong>Biometric Permission:</strong> We use biometric data for account verification. This may include fingerprints, facial recognition, or other forms of biometric data.</li>
        <li><strong>IP Address:</strong> We use your IP address to determine your country and display the relevant country code in phone fields. This helps personalize your experience and simplifies entering your contact information.</li>
        <li><strong>Device ID:</strong> We use your device's unique identifier for two-step verification. This adds an extra layer of security to your account.</li>
        <li><strong>Device Camera:</strong> We use your device's camera to take images of the trash, which are then saved to our server for later use. These images help us document and plan cleaning interventions.</li>
      </ul>
      
      <h2>2. Information Sharing and Disclosure</h2>
      <p>We do not sell, rent, or share your personal information with third parties without your consent, except when necessary to:</p>
      <ul>
        <li>Comply with applicable laws or respond to legal requests;</li>
        <li>Protect our rights or the safety of our users;</li>
        <li>Improve and optimize our services.</li>
      </ul>
      
      <h2>3. Data Security</h2>
      <p>We implement appropriate security measures to protect your information against unauthorized access, alteration, disclosure, or destruction. This includes the use of encryption technologies and strict security protocols.</p>
      
      <h2>4. Your Rights</h2>
      <p>You have the right to access, correct, or delete your personal information that we hold. You may also object to the processing of your personal data under certain circumstances. To exercise these rights, please contact us at:</p>
      <address>
        Haagbo<br/>
        1, rue du Cimetière Djama<br/>
        BP 76 Atakpamé<br/>
        TOGO<br/>
        Email: <a href="mailto:privacy@clean.africa">privacy@clean.africa</a>
      </address>
      
      <h2>5. Changes to This Policy</h2>
      <p>We reserve the right to modify this privacy policy at any time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy regularly to stay informed about how we protect your information.</p>
      
      <h2>Contact</h2>
      <p>If you have any questions or concerns about this privacy policy, please contact us at: <a href="mailto:privacy@clean.africa">privacy@clean.africa</a></p>
      
      <p>Thank you for using Clean Africa.</p>
    </div>
  );
};

export default PrivacyPolicyEN;
